/* @font-face */
@font-face {
    font-family: 'Haffer';
    src: url('/assets/fonts/haffertrial-bold-webfont.woff2') format('woff2'),
         url('/assets/fonts/haffertrial-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: bold;

}
@font-face {
    font-family: 'Haffer';
    src: url('/assets/fonts/haffertrial-medium-webfont.woff2') format('woff2'),
         url('/assets/fonts/haffertrial-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: medium;

}
@font-face {
    font-family: 'Haffer';
    src: url('/assets/fonts/haffertrial-regular-webfont.woff2') format('woff2'),
         url('/assets/fonts/haffertrial-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}
@font-face {
    font-family: 'Haffer';
    src: url('/assets/fonts/haffertrial-semibold-webfont.woff2') format('woff2'),
         url('/assets/fonts/haffertrial-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: semibold;

}