.no-internal-side-padding {
  .dx-list .dx-list-item-content {
    padding: 10px 0;
  }
}

.list-compact {
  .dx-list-item-content {
    padding: 5px;
  }
}
