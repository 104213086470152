@import "../../styles/themes/bx-variables";

.dx-button-mode-contained.dx-button-normal.dx-button {
  font-size: 16px;
}

.dx-button-text {
  font-weight: 600;
}

.dx-toolbar-menu-section .dx-button-text {
  font-weight: 400; // TODO: Buttons inside toolbar overflow don't have to be semi-bold
}

.dx-color-scheme-light {
  .dx-button-mode-contained.dx-button-normal.dx-button {
    background-color: $tab-background-color-light;
    border: none;
  }

  .dx-button-mode-contained.dx-button-normal.dx-button.dx-state-hover {
    background-color: $badge-background-color-light;
    border: none;
  }

  .dx-datagrid {
    .dx-button-mode-contained.dx-button-normal.dx-button {
      background-color: transparent;
      border-color: $border-color;
      border-radius: 3px;
      border-width: 1px;
      border-style: solid;
    }

    .dx-button-mode-contained.dx-button-normal.dx-button.dx-state-hover {
      background-color: rgba(0,0,0,0.04);
    }
  }
}

.dx-color-scheme-dark {
  .dx-button-mode-contained.dx-button-normal.dx-button {
    background-color: $tab-background-color-dark;
    border: none;
  }

  .dx-button-mode-contained.dx-button-normal.dx-button.dx-state-hover {
    background-color: $badge-background-color-dark;
    border: none;
  }

  .dx-datagrid {
    .dx-button-mode-contained.dx-button-normal.dx-button {
      background-color: transparent;
      border-color: $badge-background-color-dark;
      border-radius: 3px;
      border-width: 1px;
      border-style: solid;
    }

    .dx-button-mode-contained.dx-button-normal.dx-button.dx-state-hover {
      background-color: rgba(255,255,255,0.08);
    }
  }
}

.dx-color-scheme-dark {
  .header-button-main.dx-button-mode-text .dx-icon {
    color: $secondary-text;
  }
}

.account-email-dropdown-button .dx-button-text {
  font-weight: 700;
}