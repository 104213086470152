.dx-datagrid .dx-row > td {
  padding: 10px;
}

.dx-datagrid-content .dx-datagrid-table {
  // TODO: This makes borders slightly thicker in DCS graphs, but removes border around actions, revisit with new Figma design
  //border-collapse: separate;
}

.dx-datagrid-rowsview .dx-datagrid-content .column-align-center {
  text-align: center !important;
}

.no-x-padding { // used when located in tabbeditem
  .dx-item-content.dx-multiview-item-content {
    padding-left: 0;
    padding-right: 0;
  }
}