@import "../../styles/themes/bx-variables";

.dx-tabpanel > .dx-tabpanel-tabs .dx-tabs {
  background-color: transparent;
}

.dx-tabpanel-tabs .dx-tab {
  display: inline-table;
  text-align: center;
}

.dx-color-scheme-light {
  .dx-tabpanel > .dx-tabpanel-tabs .dx-tabs-scrollable .dx-tabs-wrapper {
    border-bottom: 1px solid $border-color;
  }

  .dx-tabpanel .dx-tab.dx-item.dx-state-hover:not(.dx-tab-selected) {
    color: transparent;
  }

  .dx-tabpanel .dx-tab.dx-item.dx-state-hover:not(.dx-tab-selected) .dx-item-content {
    color: $text-color-theme-light;
  }

  .dx-tabs {
    .dx-button-mode-contained.dx-button-normal.dx-button {
      border-bottom: 1px solid $border-color;
    }
  }
}

.dx-color-scheme-dark {
  .dx-tabpanel > .dx-tabpanel-tabs .dx-tabs-scrollable .dx-tabs-wrapper {
    border-bottom: 1px solid $badge-background-color-dark;
  }

  .dx-tabpanel .dx-tab.dx-item.dx-state-hover:not(.dx-tab-selected) {
    color: transparent;
  }

  .dx-tabpanel .dx-tab.dx-item.dx-state-hover:not(.dx-tab-selected) .dx-item-content {
    color: $text-color-theme-dark;
  }

  .dx-tabs {
    border-bottom: 1px solid $badge-background-color-dark;
  }
}