@import "../../styles/themes/bx-variables";

.dx-color-scheme-dark {
  .dx-texteditor.dx-editor-filled {
    background-color: $field-background-color-dark;
  }
}

// TODO: move this to dx-popover
.navbar-popup {
  .dx-overlay-content {
    transform: translate(0px, 53px) !important;
  }
}