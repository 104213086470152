@import "../../styles/themes/bx-variables";

.add-border {
  border-top: 1px solid $tertiary-text;
  margin: 10px 0px;
}

.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon {
  background-color: transparent;
}

.dx-list-item:is(.dx-state-active, .dx-state-focused) {
  .dropdownbutton-custom-container.delete-item-container {
    color: $text-on-dark-bg !important;

    .dx-list-item-icon {
      color: $text-on-dark-bg !important;
    }
  }

  .dx-item-content:has(> .delete-item-container) {
    background-color: $danger-text-color-light;
  }
}