.dx-slider {
  .dx-slider-handle {
    border-radius: 30px;
    height: 14px;
    margin-top: -7px
  }
}

.no-internal-side-padding {
  .dx-slider-bar {
    margin-left: 0;
    margin-right: 0;
  }
}