$sides: (
        "": "",
        "t": "top",
        "b": "bottom",
        "l": "left",
        "r": "right",
        "x": (
                "left",
                "right"
        ),
        "y": (
                "top",
                "bottom"
        )
);

$breakpoints: (
        "": "",
        "xs": 576px,
        "sm": 768px,
        "md": 992px,
        "lg": 1200px,
);

$spacingGap: 8;

@each $breakName, $breakValue in $breakpoints {
  @each $sideName, $sideValue in $sides {
    @for $i from 0 through 5 {

      $space: $i * $spacingGap;
      $selector: '';

      @if $breakName != "" {
        $selector: #{$sideName}-#{$breakName}-#{$i};
      } @else {
        $selector: #{$sideName}-#{$i};
      }

      @if $breakName != "" {
        @media (min-width: $breakValue) {
          .m#{$selector} {
            @if $sideName != "" {
              @each $pos in $sideValue {
                margin-#{$pos}: #{$space}px;
              }
            } @else {
              margin: #{$space}px;
            }

          }
          .p#{$selector} {
            @if $sideName != "" {
              @each $pos in $sideValue {
                padding-#{$pos}: #{$space}px;
              }
            } @else {
              padding: #{$space}px;
            }
          }
        }
      } @else {
        .m#{$selector} {
          @if $sideName != "" {
            @each $pos in $sideValue {
              margin-#{$pos}: #{$space}px;
            }
          } @else {
            margin: #{$space}px;
          }
        }
        .p#{$selector} {
          @if $sideName != "" {
            @each $pos in $sideValue {
              padding-#{$pos}: #{$space}px;
            }
          } @else {
            padding: #{$space}px;
          }
        }
      }
    }
  }
}