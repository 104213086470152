@import "./themes/bx-variables";

h1 {
  margin: 0;
}

h2 {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  font-weight: 900 !important;
}

h3 {
  margin: 0;
}

h4 {
  margin: 0;
  font-weight: 700 !important;
  line-height: 24px;
}

h5 {
  margin: 0;
  font-weight: 700 !important;
  font-size: 20px;
}

h6 {
  margin: 0;
  font-weight: 700 !important;
  font-size: 18px;
}

p {
  margin: 0;
}

.footer-section {
  p {
    font-size: 14px;
    font-weight: 400;
    color: $tertiary-text;
  }
}

label {
  display: inline;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600 !important;
}

a {
  text-decoration: underline;
}