@import "../../styles/themes/bx-variables";

.dx-tabs-wrapper {
  display: block;
  border-bottom: 1px solid;
  text-align: start;
}

.dx-tab.dx-tab-selected {
  border: 1px solid;
  border-bottom: 0;
  position: relative;
  bottom: -1px;
}

.dx-tab {
  display: inline-block; // TODO: This is causing some issues for tabpanel, so it's overwriten for tabpanel dx-tabs in _dx-tabpanel.scss
  padding: 0.5rem 2rem;
  margin-right: 0.5rem;
  border-bottom: 1px solid;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-weight: 600;
}

.dx-color-scheme-light {
  .dx-tabs-wrapper {
    border-bottom-color: $border-color;
  }

  .dx-tab.dx-tab-selected {
    border-color: $border-color;
  }

  .dx-tab {
    border-bottom-color: $tab-background-color-light;
  }

  .dx-tab.dx-item.dx-state-hover:not(.dx-tab-selected) {
    background-color: $tab-background-color-light;
    border-color: $tab-background-color-light;
    color: $text-color-theme-light;
  }

  .dx-tab.dx-item.dx-state-focused {
    border-color: $badge-background-color-light;
  }
}

.dx-color-scheme-dark {
  .dx-tabs-wrapper {
    border-bottom-color: $badge-background-color-dark;
  }

  .dx-tab.dx-tab-selected {
    border-color: $badge-background-color-dark;
  }

  .dx-tab {
    border-bottom-color: $tab-background-color-dark;
  }

  .dx-tab.dx-item.dx-state-hover:not(.dx-tab-selected) {
    background-color: $tab-background-color-dark;
    border-color: $tab-background-color-dark;
    color: $text-color-theme-dark;
  }

  .dx-tab.dx-item.dx-state-focused {
    border-color: $badge-background-color-dark;
  }
}