@import "../../styles/themes/bx-variables";

.top-toolbar-search-field .dx-placeholder {
  color: $tertiary-text;
}

.align-items-center { // Used if we want the DX Toolbar to vertically align all items
  .dx-toolbar-items-container {
    display: flex;
    align-items: center;
  }

  .dx-toolbar-before, .dx-toolbar-center, .dx-toolbar-after {
    top: auto;
  }
}

.dx-color-scheme-light {
  // app-header (top nav bar) icons
  .top-toolbar-user-icon .dx-button-mode-text .dx-icon {
    color: $tertiary-text;
  }
}

.dx-color-scheme-dark {
  // app-header (top nav bar) icons
  .top-toolbar-user-icon .dx-button-mode-text .dx-icon {
    color: $secondary-text;
  }
}