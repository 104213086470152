@import "../../styles/themes/bx-variables";

.dataframe-item-dialog { // Used for TextBox inside dataframe item dialog, that is set as read only
  .dx-texteditor.dx-state-readonly {
    border-style: solid;
  }

  .dx-texteditor-input {
    color: $secondary-text;
  }
}