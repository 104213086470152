@import "../../styles/themes/bx-variables";

// These classes are for the indicators inside of the buttons (CIRCULAR PROGRESS WHEEL)
.button-with-indicator .dx-button-content {
  position: relative;
}

.button-with-indicator .button-indicator {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-with-indicator.dx-button-mode-contained {
  .dx-loadindicator-segment {
    background-color: $text-color-theme-dark; // TODO: add colors for outlined and text buttons (success, normal, danger, default)
  }
}

// component-hide could potentially be used outside of the loadindicators, maybe we can move it to some generic .scss
.component-hide {
  visibility: hidden;
}