body {
  font-family: Haffer, Roboto, sans-serif !important;
  line-height: 24px;
  background: #F1F3FF url('/assets/img/bg-grid.png') top right no-repeat;
  padding-top: 4rem;
}

.App-header {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #F1F3FF;
  border-bottom: 1px solid #D6E3F6;
  position: fixed;
  top: 0;
  width: calc(100% - 4rem);
  z-index: 99;
  img {
    max-height: 50px;
  }
  .dx-button-text {
    font-weight: 400;
    color: rgb(6, 69, 240);
    font-size: 1rem;
  }
}

.gallery-wrapper {
  .dx-gallery-item {
    border-radius: 1rem;
  }
  .gallery-item {
    position: relative;
    .gallery-caption {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      // background: #fff;
      padding: 4rem;
      text-align: left;
      max-width: 50%;
      white-space: normal;
      h2 {
        font-size: 2.5rem;
        color: rgb(6, 69, 240);
        font-weight: 400 !important;
      }
      p {
        color: #979797;
        font-size: 1.5rem;
      }
      a {
        display: inline-block;
        font-weight: 400;
        font-size: 1.15rem;
        text-decoration: none;
        color: #fff;
        background: #03D187;
        padding: .75rem 1.5rem;
        border-radius: 1.5rem;
        transition: all .2s ease-in-out;
        margin-top: 2rem;
        white-space: nowrap;
        &:hover {
          background: #02C27D;
        }
      }
    }
  }
  .dx-gallery-indicator {
    bottom: 50px;
    padding-right: 30px;
    text-align: right;
    .dx-gallery-indicator-item {
      box-sizing: border-box;
      border: none;
      width: 12px;
      height: 12px;
      margin: 1px 6px !important;
    }
  }
}

.tags-wrapper {
  .tags {
    margin: 0;
    padding: 0;
    list-style: none;
    .tag-item {
      display: inline-block;
      margin: 0 1rem 1rem 0;
      a {
        display: block;
        color: #000;
        font-size: 14px;
        border: 1px solid #000;
        border-radius: 5px;
        padding: .3rem .8rem;
        text-decoration: none;
        transition: all .2s ease-in-out;
        &:hover {
          color: rgb(6, 69, 240);
          border: 1px solid rgb(6, 69, 240);
        }
      }
    }
  }
}

.welcome-wrapper {
  h1 {
    font-size: 2.5rem;
    color: rgb(6, 69, 240);
    font-weight: 400 !important;
    margin: 2rem 0;
  }
}

.bots-wrapper {
  .bot-item {
    display: block;
    min-height: 320px;
    position: relative;
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
    background: #fff;
    &:hover {
      box-shadow: 0 8px 8px -4px rgb(0 0 0 / 3%), 0 20px 30px -10px rgb(0 0 0 / 15%);
      border: none !important;
      cursor: pointer;
    }
    &:focus {
      border-color: #fff !important;
    }
    h2 {
      font-weight: 400 !important;
      margin-bottom: 1.25rem;
    }
    p {
      color: #979797;
    }
    .bot-price {
      font-size: 1.15rem;
      font-weight: 600;
      position: absolute;
      top: 30px;
      right: 30px;
    }
    .bot-price.free {
      color: #00C5FE;
    }
    .bot-price.premium {
      padding-left: 2rem;
      background: url('/assets/img/ico-crown.png') center left no-repeat;
    }
    .bot-badge {
      display: inline-block;
      color: rgb(6, 69, 240);
      border: 1px solid rgb(6, 69, 240);
      border-radius: 5px;
      padding: .4rem .8rem;
      margin: 1.25rem 0;
    }
  }
}

// .tiles-wrapper {
//   .dx-tile {
//     padding: 2rem;
//     border-radius: 1rem;
//     &:hover {
//       box-shadow: 0 8px 8px -4px rgb(0 0 0 / 3%), 0 20px 30px -10px rgb(0 0 0 / 15%);
//       border: none !important;
//       cursor: pointer;
//     }
//     &:focus {
//       border-color: #fff !important;
//     }
//     .bot-item {
//       position: relative;
//       h2 {
//         font-weight: 400 !important;
//         margin-bottom: 1.25rem;
//       }
//       p {
//         color: #979797;
//       }
//       .bot-price {
//         font-size: 1.15rem;
//         font-weight: 600;
//         position: absolute;
//         top: 0;
//         right: 0;
//       }
//       .bot-price.free {
//         color: #00C5FE;
//       }
//       .bot-price.premium {
//         padding-left: 2rem;
//         background: url('/assets/img/ico-crown.png') center left no-repeat;
//       }
//       .bot-badge {
//         display: inline-block;
//         color: rgb(6, 69, 240);
//         border: 1px solid rgb(6, 69, 240);
//         border-radius: 5px;
//         padding: .4rem .8rem;
//         margin: 1.25rem 0;
//       }
//     }
//   }
// }

.solution-detail {
  padding-top: 2rem;
  h1 {
    font-size: 2.5rem;
    color: rgb(6, 69, 240);
    font-weight: 400 !important;
    margin: 2rem 0;
    line-height: 3rem;
  }
  .detail-content {
    h2 {
      font-size: 1.5rem;
      font-weight: 600 !important;
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 2rem;
    }
    img {
      border-radius: 1rem;
    }
    .features {
      color: #fff;
      background: rgb(6, 69, 240);
      padding: 2rem;
      border-radius: 1rem;
      ul {
        li {
          padding: .25rem 0;
        }
      }
    }
  }
  .detail-info {
    background: #fff;
    border-radius: 1rem;
    padding: 2rem;
    .detail-price {
      font-size: 1.15rem;
      font-weight: 600;
      display: flex;
      padding: 1rem 0;
      .bot-price.premium {
        padding-left: 2rem;
        background: url('/assets/img/ico-crown.png') center left no-repeat;
      }
      a {
        display: inline-block;
        font-weight: 400;
        font-size: 1.15rem;
        text-decoration: none;
        color: #fff;
        background: #03D187;
        padding: .75rem 1.5rem;
        border-radius: 1.5rem;
        transition: all .2s ease-in-out;
        margin-left: 2rem;
        white-space: nowrap;
        &:hover {
          background: #02C27D;
        }
      }
    }
    .detail-tags {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        .tag-item {
          display: inline-block;
          margin: 0 1rem 1rem 0;
          a {
            display: block;
            color: #000;
            font-size: 14px;
            border: 1px solid #000;
            border-radius: 5px;
            padding: .3rem .8rem;
            text-decoration: none;
            transition: all .2s ease-in-out;
            &:hover {
              color: rgb(6, 69, 240);
              border: 1px solid rgb(6, 69, 240);
            }
          }
        }
      }
    }
    .detail-table {
      table {
        tr {
          &:last-child {
            td {
              border: none;
            }
          }
        }
        td {
          padding: 1rem 0;
          border-bottom: 1px solid #D6E3F6;
          vertical-align: top;
          color: #979797;
          strong {
            font-weight: 400;
            color: #000;
          }
          ul {
            padding: 0;
            margin: 0;
            list-style: none;
            li {
              padding-bottom: .5rem;
              img {
                margin-right: 5px;
                position: relative;
                top: 4px;
              }
              a {
                color: #000;
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.App-footer {
  background: #fff url('/assets/img/bg-grid-footer.png') bottom center no-repeat;
  .container {
    padding: 4rem 1rem 3rem;
  }
  p {
    font-size: 0.9rem;
  }
}

.breadcrumbs {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      display: inline-block;
      color: #bbb;
      font-size: 1rem;
      &:after {
        content: "/";
        margin: 0 .5rem;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      a {
        color: #bbb;
        text-decoration: none;
        transition: all .2s ease-in-out;
        &:hover {
          color: #888;
        }
      }
    }
  }
}

hr {
  border-top: 1px solid #D6E3F6;
  color: #D6E3F6;
  margin: 2rem 0;
}

.responsive-img {
  max-width: 100%;
  height: auto;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 550px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 740px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 980px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
}

@media (max-width: 1199px) {
  .tag-item {
    margin: 0 .75rem .75rem 0 !important;
    a {
      font-size: 13px !important;
      padding: 0.2rem 0.6rem !important;
    }
  }
}
@media (max-width: 991px) {
  
}
@media (max-width: 767px) {
  body {
    padding-top: 6rem;
  }
  .App-header {
    padding: 1rem;
    flex-direction: column;
    width: calc(100% - 2rem);
    .actions-wrapper {
      margin-top: 1rem;
      .dx-button-content {
        padding: 5px 10px;
      }
    }
  }
  .detail-wrapper {
    .features {
      padding: 1.5rem !important;
    }
    .detail-info {
      padding: 1.5rem;
      .detail-price {
        flex-direction: column;
        a {
          margin-left: 0;
          margin-top: 2rem;
        }
      }
    }
  }
  .welcome-wrapper {
    padding: 0 .5rem;
    h1 {
      font-size: 2rem;
    }
  }
  .gallery-wrapper {
    padding: 0 .5rem;
    .gallery-item {
      max-height: 400px;
      img {
        max-height: 400px;
        position: relative;
        margin-left: -40%;
      }
    }
    .gallery-caption {
      padding: 2rem !important;
      max-width: 70% !important;
      h2 {
        font-size: 2rem !important;
      }
      p {
        font-size: 1.15rem !important;
      }
    }
  }
  .tags-wrapper {
    padding: 0 .5rem;
    .tag-item {
      margin: 0 .5rem .75rem 0 !important;
      a {
        font-size: 12px !important;
        padding: 0.1rem 0.5rem !important;
      }
    }
  }
  .bots-wrapper {
    .bot-item {
      padding-bottom: 1rem;
    }
  }
  .dx-gallery-indicator {
    bottom: 35px !important;
    padding-right: 15px !important;
  }
}
@media (max-width: 525px) {
  .gallery-wrapper {
    .gallery-item {
      img {
        margin-left: -80%;
      }
    }
    .gallery-caption {
      max-width: 80% !important;
    }
  }
}
