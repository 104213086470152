@use "sass:color";

// For custom linear progress bar component - please do not remove during MUI removal
$bx_progress_bar_linear_bg_color_light: rgba(5, 114, 206, 0.2);
$bx_progress_bar_linear_bg_color_dark: rgba(0, 138, 255, 0.42);
$bx_progress_bar_buffer_linear_bg_color_light: rgba(153, 189, 219, 1);
$bx_progress_bar_buffer_linear_bg_color_dark: rgb(47, 82, 112);

// Generic colors that should correspond to the main color light and dark themes
// Accent - light theme
$accent-color-light: rgb(6, 69, 240);
$accent-color-faded-light: rgba(180, 199, 250, 0.2);
$accent-text-color-light: rgb(1, 67, 97);
// Accent - dark theme
$accent-color-dark: rgb(0, 126, 242);
$accent-color-faded-dark: rgb(229, 246, 253); // keeping this here, in case we want to have different color for dark theme
$accent-text-color-dark: rgb(1, 67, 97); // keeping this here, in case we want to have different color for dark theme

// Danger - light theme
$danger-background-color-light: rgb(212, 63, 58);
$danger-background-color-faded-light: rgb(254, 228, 213);
$danger-text-color-light: rgb(252, 58, 49);
// Danger - dark theme
$danger-background-color-dark: rgb(217, 83, 79);
$danger-background-color-faded-dark: rgb(254, 228, 213); // keeping this here, in case we want to have different color for dark theme
$danger-text-color-dark: rgb(252, 58, 49); // keeping this here, in case we want to have different color for dark theme

// Success - light theme
$success-background-color-light: rgb(83, 199, 39);
$success-background-color-faded-light: rgb(237, 252, 210);
$success-text-color-light: rgb(81, 158, 54);
// Success - dark theme
$success-background-color-dark: rgb(81, 158, 54);
$success-background-color-faded-dark: rgb(237, 247, 237); // keeping this here, in case we want to have different color for dark theme
$success-text-color-dark: rgb(30, 70, 32); // keeping this here, in case we want to have different color for dark theme

// Warning - light theme
$warning-background-color-light: rgb(240, 173, 78);
$warning-background-color-faded-light: rgb(255, 244, 229);
$warning-text-color-light: rgb(102, 60, 0);
// Warning - dark theme
$warning-background-color-dark: rgb(240, 173, 78);
$warning-background-color-faded-dark: rgb(255, 244, 229); // keeping this here, in case we want to have different color for dark theme
$warning-text-color-dark: rgb(102, 60, 0); // keeping this here, in case we want to have different color for dark theme

$border-color-light: rgba(0, 0, 0, 0.12);
$border-color-dark: rgba(255, 255, 255, 0.12);

$theme-border-color-dark: rgba(66, 66, 66, 1);

// Basic theme colors defined here, because scss can't get them automatically from DX theme
$theme-background-color-dark: rgba(42, 42, 42, 1);

// Main theme colors
$background-color-dark: #202020;
$background-color-secondary-dark: color.scale($background-color-dark, $lightness: 8%); // #323232
$field-background-color-dark: color.scale($background-color-dark, $lightness: 39%); // #757575
$secondary-text: color.scale($background-color-dark, $lightness: 53%); // #979797
$tertiary-text: color.scale($background-color-dark, $lightness: 74%); // #C4C4C4
$switcher-color: color.scale($background-color-dark, $lightness: 83%); // #D9D9D9
$border-color: color.scale($background-color-dark, $lightness: 89%); // #E5E5E5
$text-color-theme-dark: #FFF;
$text-color-theme-light: #202427;
$selected-row: #f1fcff;
$hovered-row: #f5f8f9;
$text-on-dark-bg: #FFF;

// Secondary theme colors
$tab-background-color-dark: #424343;
$badge-background-color-dark: color.scale($tab-background-color-dark, $lightness: 18%); // #636464
$tab-background-color-light: #F5F8F9;
$badge-background-color-light: #D8DEE3;